<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b>{{"Compilations Assistant" | translate}}</b>
    </span>
    <div class="elements" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="filters">
            <div class="filter" *ngFor="let filter of filters; let i = index;">
                <div class="d-f-c">        
                    <mat-form-field appearance="legacy" class="full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{"Group" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.group_id">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let fil of groups$ | async" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{"Job type" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.task_operation_operation_id">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="contentTypeMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let fil of jobTypes$ | async" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{"Job status" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.operation_status_id">
                            <mat-option *ngFor="let fil of operationStatuses" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{ "Job Tag" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="filter.task_operation_parameter_value_id">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="filter.task_operation_parameter_value_id">
                                    <span>{{ getValuesById(filter.task_operation_parameter_value_id)?.value }}</span>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>
    
    
                <div class="d-f-c" style="margin-left: 100px;">
                    <button class="show_on_hover" mat-icon-button (click)="delFilter()" *ngIf="filters.length > 1">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button class="show_on_hover" mat-icon-button (click)="duplicate(filter)">
                        <mat-icon>control_point_duplicate</mat-icon>
                    </button>
                    <ng-container *ngIf="i == filters.length - 1">
                        <button mat-icon-button (click)="addFilter()">
                            <mat-icon>add</mat-icon>
                        </button>

                        <button mat-raised-button style="color: #fff" class="ml-1" color="primary" (click)="filterCards()" [disabled]="isLoad" #tooltip="matTooltip"
                        matTooltip="{{ '3' | translate }}"
                        [matTooltipPosition]="'above'">
                            {{ "Get Cards" | translate }}
                        </button>
                    </ng-container>
                </div>
            </div>

            <div class="elements_wrp">
                <div class="element text_one_line" (click)="openTask(item)" *ngFor="let item of tasks">
                    <div class="ids">
                        <div>{{item.custom_id}}</div>
                        <div>{{item.internal_id}}</div>
                    </div>
                    <div class="name text_one_line">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <div>
                <div class="loader_line" *ngIf="!!totalTasksCount">
                    <div class="loader_out">
                        <mat-progress-spinner
                            [ngClass]="{'success': tasks.length == totalTasksCount}"
                            class="example-margin"
                            [color]="'primary'"
                            [mode]="'determinate'"
                            [value]="((tasks.length/totalTasksCount)*100).toFixed(0)">
                        </mat-progress-spinner>
                        <ng-container *ngIf="tasks.length == totalTasksCount; else elseTemplateGetFinishVal">
                            <span class="progress_val">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="#44bd32" stroke-width="5" stroke-dasharray="100">
                                    <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
                                        <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="1" dur="2s"></animate>
                                    </path>
                                </svg>
                            </span>
                        </ng-container>
                        <ng-template #elseTemplateGetFinishVal>
                            <span class="progress_val">{{((tasks.length/totalTasksCount)*100).toFixed(0)}}</span>
                        </ng-template>
                    </div>
                    <div class="loader_title">
                        <ng-container *ngIf="tasks.length == totalTasksCount; else elseTemplateGetFinish">
                            {{'All cards scanned' | translate}}. 
                        </ng-container>
                        <ng-template #elseTemplateGetFinish>
                            {{"Getting cards" | translate}} 
                            <span class="have">{{tasks.length}}</span> <span class="split">/</span> <span class="need">{{totalTasksCount}}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="loader_line" *ngIf="!!totalTasksCount">
                    <div class="loader_out">
                        <mat-progress-spinner
                            [ngClass]="{'success': checkNeededEndCount() == totalTasksCount}"
                            class="example-margin"
                            [color]="'primary'"
                            [mode]="'determinate'"
                            [value]="((checkNeededEndCount()/totalTasksCount)*100).toFixed(0)">
                        </mat-progress-spinner>
                        <ng-container *ngIf="checkNeededEndCount() == totalTasksCount; else elseTemplateGetFinishVal">
                            <span class="progress_val">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="#44bd32" stroke-width="5" stroke-dasharray="100">
                                    <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
                                        <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="1" dur="2s"></animate>
                                    </path>
                                </svg>
                            </span>
                        </ng-container>
                        <ng-template #elseTemplateGetFinishVal>
                            <span class="progress_val">{{((checkNeededEndCount()/totalTasksCount)*100).toFixed(0)}}</span>
                        </ng-template>
                    </div>
                    <div class="loader_title">
                        <ng-container *ngIf="checkNeededEndCount() == totalTasksCount; else elseTemplateGetFinish">
                            {{'All tags checked' | translate}}. 
                        </ng-container>
                        <ng-template #elseTemplateGetFinish>
                            {{"Getting Tags" | translate}} 
                            <span class="have">{{checkNeededEndCount()}}</span> <span class="split">/</span> <span class="need">{{totalTasksCount}}</span>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="result" *ngIf="tasks.length == totalTasksCount && !!totalTasksCount">
                {{tasks.length}} {{"cards matched" | translate}}
            </div>
        </div>
        <div class="d-f-c">
            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <button class="ml-1" mat-raised-button color="primary" style="color: #fff;" (click)="compilations()" #tooltip="matTooltip"
            matTooltip="{{ '2' | translate }}"
            [matTooltipPosition]="'above'" [disabled]="stage != 'To Compilation wizard'">
                {{ stage | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>