import { Compiler, CompilerFactory, COMPILER_OPTIONS, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { HeadersInterceptor } from './shared/interceptors/headers.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { environment } from "src/environments/environment";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackBarItem } from './shared/global_components/snack-bar/snack-bar-item';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { HeaderComponent } from './shared/global_components/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon'; 
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { CompanyCreateComponent } from './components/company-create/company-create.component';
import { CompanyEditComponent } from './components/company-edit/company-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyComponent } from './components/workspace-settings/categorization/groups/company/company.component';
import { GroupEditComponent } from './components/workspace-settings/categorization/groups/group-edit/group-edit.component';
import { GroupCreateComponent } from './components/workspace-settings/categorization/groups/group-create/group-create.component';
import { MatSelectModule } from '@angular/material/select';
import { ProfileCreateComponent } from './components/workspace-settings/publication-outlets/profile-create/profile-create.component';
import { ProfileEditComponent } from './components/workspace-settings/publication-outlets/profile-edit/profile-edit.component';
import { ProfileComponent } from './components/workspace-settings/publication-outlets/profile/profile.component';
import { UsersComponent } from './components/workspace-settings/members/users/users.component';
import { UserCreateComponent } from './components/workspace-settings/members/users/user-create/user-create.component';
import { UserEditComponent } from './components/workspace-settings/members/users/user-edit/user-edit.component';
import { TaskEditComponent } from './components/atTasksDialog/task-edit/task-edit.component';
import { MatTableModule } from '@angular/material/table';
import { WorkAddComponent } from './components/atTasksDialog/work-add/work-add.component';
import { WorkEditComponent } from './components/atTasksDialog/work-edit/work-edit.component';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import { TaskProfileAddComponent } from './components/atTasksDialog/task-profile-add/task-profile-add.component';
import { TaskProfileEditComponent } from './components/atTasksDialog/task-profile-edit/task-profile-edit.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EmployeeAddComponent } from './components/workspace-settings/members/employees/employee-add/employee-add.component';
import { EmployeesComponent } from './components/workspace-settings/members/employees/employees.component';
// import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LinkyModule } from 'ngx-linky';
import { WorkEmplEditComponent } from './components/atTasksDialog/work-empl-edit/work-empl-edit.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReportsNumber } from './shared/pipes/number.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EmplCreateTaskComponent } from './components/atTasksDialog/empl-create-task/empl-create-task.component';
import { CreateTaskComponent } from './components/atTasksDialog/create-task/create-task.component';
import { TaskCommentEditComponent } from './components/atTasksDialog/task-comment-edit/task-comment-edit.component';
import { EmployeeEditComponent } from './components/workspace-settings/members/employees/employee-edit/employee-edit.component';
import { GroupAddComponent } from './components/atTasksDialog/group-add/group-add.component';
import { TaskGroupEditComponent } from './components/atTasksDialog/group-edit/group-edit.component';
import { TaskStatusEditComponent } from './components/atTasksDialog/task-status-edit/task-status-edit.component';
import { TaskIdEditComponent } from './components/atTasksDialog/task-id-edit/task-id-edit.component';
import { WorkOperationIdEditComponent } from './components/atTasksDialog/work-operation-id-edit/work-operation-id-edit.component';
import { WorkCompletedAtEditComponent } from './components/atTasksDialog/work-completed-at-edit/work-completed-at-edit.component';
import { WorkEmployeeEditComponent } from './components/atTasksDialog/work-employee-edit/work-employee-edit.component';
import { WorkStatusEditComponent } from './components/atTasksDialog/work-status-edit/work-status-edit.component';
import { WorkRateEditComponent } from './components/atTasksDialog/work-rate-edit/work-rate-edit.component';
import { WorkCommentEditComponent } from './components/atTasksDialog/work-comment-edit/work-comment-edit.component';
import { WorkEmployeeCommentEditComponent } from './components/atTasksDialog/work-employee-comment-edit/work-employee-comment-edit.component';
import { NotificationsBar } from './shared/global_components/notifications-bar/notifications-bar';
import { MatRippleModule } from '@angular/material/core'; 
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WorkEmplAddComponent } from './components/atTasksDialog/work-empl-add/work-empl-add.component';
import { LongPressDirective } from './shared/directives/long-press.directive';
import { NgxLongPress2Module } from 'ngx-long-press2';
import { StatByMonthComponent } from './components/workspace-settings/discontinued/stat-by-month/stat-by-month.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ScrollingModule } from '@angular/cdk/scrolling'; 
import { MatSelectFilterModule } from 'mat-select-filter';
import { ConnectionsModalComponent } from './shared/global_components/connections-modal/connections-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SplitPipe } from './shared/pipes/table.pipe';
import { DateAskComponent } from './shared/global_components/date-ask/date-ask.component';
import { MatTabsModule } from '@angular/material/tabs';
import { OperationChatComponent } from './components/atTasksDialog/operation-chat/operation-chat.component';
import { CreateOperationChatComponent } from './components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { OperationChatsComponent } from './components/atTasksDialog/operation-chats/operation-chats.component'; 
import { LoaderMoreComponent } from './shared/global_components/loader-more/loader-more.component';
import { MatRadioModule } from '@angular/material/radio';
import { EditOperationChatComponent } from './components/atTasksDialog/edit-operation-chat/edit-operation-chat.component';
import { SwiperModule } from 'swiper/angular';
import { MatBadgeModule } from '@angular/material/badge';
import { ChatsComponent } from './components/chats/chats.component'; 
import { YearPipe } from './shared/pipes/year.pipe';
import { StatByMonthCreatorComponent } from './components/workspace-settings/discontinued/stat-by-month-creator/stat-by-month-creator.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CloseFromBackdropComponent } from './shared/global_components/close-from-backdrop/close-from-backdrop.component';
import { TasksHaveSameUrlComponent } from './shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { SameUrlTasksComponent } from './components/atTasksDialog/same-url-tasks/same-url-tasks.component';
import { WorkToApproveComponent } from './shared/global_components/work-to-approve/work-to-approve.component';
import { MobChatStatusEditComponent } from './components/atTasksDialog/mob-chat-status-edit/mob-chat-status-edit.component';
import { ChatComponent } from './shared/global_components/chat/chat.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EditClientComponent } from './components/clients/edit-client/edit-client.component';
import { ClientsListComponent } from './components/clients/clients-list/clients-list.component';
import { AddClientComponent } from './components/clients/add-client/add-client.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { TaskClientsComponent } from './components/clients/task-clients/task-clients.component';
import { TaskAddClientComponent } from './components/clients/task-add-client/task-add-client.component';
import { PaymentsComponent } from './components/workspace-settings/finances/payment/payments/payments.component';
import { AddPaymentComponent } from './components/workspace-settings/finances/payment/add-payment/add-payment.component';
import { EditPaymentComponent } from './components/workspace-settings/finances/payment/edit-payment/edit-payment.component';
import { CreateChecklistComponent } from './components/workspace-settings/automation/checklists/create-checklist/create-checklist.component';
import { EditChecklistComponent } from './components/workspace-settings/automation/checklists/edit-checklist/edit-checklist.component';
import { ChecklistComponent } from './components/workspace-settings/automation/checklists/checklist/checklist.component';
import { CheckListComponent } from './components/atTasksDialog/check-list/check-list.component';
import { QueastsListComponent } from './components/workspace-settings/members/quests/queasts-list/queasts-list.component';
import { AddQuestComponent } from './components/workspace-settings/members/quests/add-quest/add-quest.component';
import { EditQuestComponent } from './components/workspace-settings/members/quests/edit-quest/edit-quest.component';
import { AddFieldComponent } from './components/workspace-settings/members/quests/a-Dialog/add-field/add-field.component';
import { EditFieldComponent } from './components/workspace-settings/members/quests/a-Dialog/edit-field/edit-field.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { AddQuestToEmplComponent } from './components/workspace-settings/members/quests/a-Dialog/add-quest-to-empl/add-quest-to-empl.component';
import { QuestComponent } from './components/workspace-settings/members/quests/quest/quest.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WorkMultiAddComponent } from './components/atTasksDialog/work-multi-add/work-multi-add.component';
import { AdditionalEmployeesComponent } from './components/additionalEmpl/additional-employees/additional-employees.component';
import { AddAdditionalEmployeesComponent } from './components/additionalEmpl/add-additional-employees/add-additional-employees.component';
import { EditAdditionalEmployeesComponent } from './components/additionalEmpl/edit-additional-employees/edit-additional-employees.component';
import { ChangeStatusComponent } from './components/workspace-settings/members/quests/a-Dialog/change-status/change-status.component';
import { ReminderAlertComponent } from './shared/global_components/reminder-alert/reminder-alert.component';
import { DeleteAlertComponent } from './shared/global_components/delete-alert/delete-alert.component';
import { FileInterfaceComponent } from './shared/global_components/file-interface/file-interface.component';
import { FileSizePipe } from './shared/pipes/filesize.pipe';
import { FileManagerComponent } from './shared/global_components/file-manager/file-manager.component';
import { RenameComponent } from './shared/global_components/file-manager/dialog/rename/rename.component';
import { MoveComponent } from './shared/global_components/file-manager/dialog/move/move.component';
import { CreateFolderComponent } from './shared/global_components/file-manager/dialog/create-folder/create-folder.component';
import { VideoViewerComponent } from './shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { VimeModule } from '@vime/angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatChipsModule } from '@angular/material/chips';
import { FileSettingsComponent } from './shared/global_components/file-manager/dialog/file-settings/file-settings.component';
import { IntPipe } from './shared/pipes/int.pipe';
import { DndDirective } from './shared/directives/dnd.directive';
import { NgxViewerModule } from 'ngx-viewer';
import { FilesUploadAskComponent } from './shared/global_components/files-upload-ask/files-upload-ask.component';
import { EnterSubmitDirective } from './shared/directives/enter.submit';
import { SizePerSec } from './shared/pipes/sizePerSec.pipe';
import { fromNowToTime } from './shared/pipes/fromNowToTime';
import { CasesComponent } from './components/workspace-pages/cases/cases.component';
import { A11yModule } from '@angular/cdk/a11y';
import { OpenTaskComponent } from './components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { InterfaceComponent } from './shared/global_components/file-manager/dialog/interface/interface.component';
import { WorkEmplStatusEditComponent } from './components/atTasksDialog/work-empl-status-edit/work-empl-status-edit.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChangeSizeLimitComponent } from './components/admin/dialogs/change-size-limit/change-size-limit.component';
import { InviteDialogComponent } from './components/atTasksDialog/invite-dialog/invite-dialog.component';
import { RulesComponent } from './components/workspace-settings/members/employees/dialogs/rules/rules.component';
import { EditPublishedAtComponent } from './components/atTasksDialog/profiles/edit-published-at/edit-published-at.component';
import { EditStatusComponent } from './components/atTasksDialog/profiles/edit-status/edit-status.component';
import { EditChannelComponent } from './components/atTasksDialog/profiles/edit-channel/edit-channel.component';
import { EditContentNameComponent } from './components/atTasksDialog/profiles/edit-content-name/edit-content-name.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { LoaderRequestsComponent } from './shared/global_components/loader-requests/loader-requests.component';
import { TaskMultiAddClientsComponent } from './components/clients/task-multi-add-clients/task-multi-add-clients.component';
import { SetUpComponent } from './components/main/set-up/set-up.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SetComponent } from './components/main/set/set.component';
import { CreateTaskStatusComponent } from './components/main/dialogs/types/create-task-status/create-task-status.component';
import { EditTaskStatusComponent } from './components/main/dialogs/types/edit-task-status/edit-task-status.component';
import { EditWorkStatusComponent } from './components/main/dialogs/types/edit-work-status/edit-work-status.component';
import { CreateWorkStatusComponent } from './components/main/dialogs/types/create-work-status/create-work-status.component';
import { JobTypesComponent } from './components/workspace-settings/categorization/types/job-types/job-types.component';
import { TaskTypesComponent } from './components/workspace-settings/categorization/types/task-types/task-types.component';
import { PaymentInfoComponent } from './components/main/dialogs/member/payment-info/payment-info.component';
import { DialogGroupEditComponent } from './components/main/dialogs/groups/group-edit/group-edit.component';
import { DialogGroupCreateComponent } from './components/main/dialogs/groups/group-create/group-create.component';
import { TaskManagerAddComponent } from './components/workspace-pages/cases/dialogs/managers/task-manager-add/task-manager-add.component';
import { GroupManagerAddComponent } from './components/workspace-pages/cases/dialogs/managers/group-manager-add/group-manager-add.component';
import { GroupManagersComponent } from './components/workspace-pages/cases/dialogs/managers/group-managers/group-managers.component';
import { TaskManagersComponent } from './components/workspace-pages/cases/dialogs/managers/task-managers/task-managers.component';
import { PartnersListComponent } from './components/main/dialogs/partners/partners-list/partners-list.component';
import { AddPartnerComponent } from './components/main/dialogs/partners/add-partner/add-partner.component';
import { PartnerDialogComponent } from './components/main/dialogs/partners/partner-dialog/partner-dialog.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { SetEmplComponent } from './components/workspace-pages/cases/dialogs/members/set-empl/set-empl.component';
import { ExecutorsComponent } from './components/workspace-pages/cases/dialogs/executors/executors.component';
import { EditPriceComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-price/edit-price.component';
import { EditTypeComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-type/edit-type.component';
import { EditExecutorComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-executor/edit-executor.component';
import { EditEmplStatusComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-status/edit-status.component';
import { EditCompletedAtComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-completed-at/edit-completed-at.component';
import { ChatsPageComponent } from './components/workspace-pages/chats-page/chats-page.component';
import { NgAudioRecorderModule } from 'ng-audio-recorder';
import { SafePipe } from './shared/pipes/safe.pipe';
import { AudioRecordingService } from './shared/services/common/media/audio-recording.service';
import { VideoRecordingService } from './shared/services/common/media/video-recording.service';
import { EditChatStatusComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-chat-status/edit-chat-status.component';
import { EditStartAtComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-start-at/edit-start-at.component';
import { EditChatAssStatusComponent } from './components/workspace-pages/cases/dialogs/executors/dialogs/edit-chat-ass-status/edit-chat-ass-status.component';
import { BoardPageComponent } from './components/workspace-pages/board-page/board-page.component';
import { ChooseStatusComponent } from './components/workspace-pages/board-page/dialogs/choose-status/choose-status.component';
import { PersonalStatusesComponent } from './shared/global_components/chat/dialogs/personal-statuses/personal-statuses.component';
import { MobPersonalStatusesComponent } from './shared/global_components/chat/dialogs/mob-personal-statuses/mob-personal-statuses.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MoveJobsComponent } from './components/workspace-pages/cases/dialogs/move-jobs/move-jobs.component';
import { StatusReasonComponent } from './components/workspace-pages/cases/dialogs/status-reason/status-reason.component';
import { MultiEditStartAtComponent } from './components/workspace-pages/board-page/dialogs/multi-edit-start-at/multi-edit-start-at.component';
import { AddChatToBoardComponent } from './components/workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { ToApproveStepperComponent } from './components/workspace-pages/cases/dialogs/to-approve-stepper/to-approve-stepper.component';
import { StatementsComponent } from './components/workspace-settings/finances/statements/statements.component';
import { StatementComponent } from './components/workspace-settings/finances/statements/dialogs/statement/statement.component';
import { ConnectedAppsComponent } from './components/connected-apps/connected-apps.component';
import { EditChannelAccountComponent } from './components/connected-apps/dialogs/edit-channel-account/edit-channel-account.component';
import { ConnectWithYoutubeComponent } from './components/workspace-settings/publication-outlets/profile-edit/dialogs/connect-with-youtube/connect-with-youtube.component';
import { SelectContentComponent } from './shared/global_components/file-manager/dialog/select-content/select-content.component';
import { OpenJobComponent } from './components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { TimeFormatPipe } from './shared/pipes/timeFormat';
import { MoveToOtherJobComponent } from './shared/global_components/chat/dialogs/move-to-other-job/move-to-other-job.component';
import { JobsComponent } from './components/workspace-pages/jobs/jobs.component';
import { WorkspaceDashboardComponent } from './components/workspace-pages/workspace-dashboard/workspace-dashboard.component';
import { TaskFilterComponent } from './components/workspace-pages/workspace-dashboard/dialogs/task-filter/task-filter.component';
import { ChatFilterComponent } from './components/workspace-pages/workspace-dashboard/dialogs/chat-filter/chat-filter.component';
import { BoardFilterComponent } from './components/workspace-pages/workspace-dashboard/dialogs/board-filter/board-filter.component';
import { ApproveJobComponent } from './shared/global_components/file-manager/dialog/approve-job/approve-job.component';
import { SelectChecklistComponent } from './shared/global_components/file-manager/dialog/select-checklist/select-checklist.component';
import { FinancesComponent } from './components/workspace-settings/finances/finances.component';
import { ScenariosComponent } from './components/workspace-settings/automation/scenarios/scenarios.component';
import { AddAutomationTemplateComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-automation-template/add-automation-template.component';
import { EditApproveComponent } from './components/workspace-settings/automation/scenarios/dialogs/edit-approve/edit-approve.component';
import { JobAutomationComponent } from './components/workspace-pages/jobs/dialogs/job-automation/job-automation.component';
import { AddJobAutomationComponent } from './components/workspace-pages/jobs/dialogs/add-job-automation/add-job-automation.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { SelectDirective } from './shared/directives/select.directive';
import { TaskChatsComponent } from './components/atTasksDialog/task-chats/task-chats.component';
import { MinimizeInterfaceComponent } from './shared/global_components/minimize-interface/minimize-interface.component';
import { MiniChatComponent } from './shared/global_components/chat/dialogs/mini-chat/mini-chat.component';
import { CreateNewTaskComponent } from './components/create-new-task/create-new-task.component';
import { PartnerCreateTaskComponent } from './components/atTasksDialog/partner-create-task/partner-create-task.component';
import { AutoComponent } from './components/workspace-settings/automation/scenarios/dialogs/auto/auto.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AddAndEditTaskTemplateComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-and-edit-task-template/add-and-edit-task-template.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ApproveRejectFilesComponent } from './shared/global_components/approve-reject-files/approve-reject-files.component';
import { WorkspaceParametersComponent } from './components/workspace-settings/categorization/workspace-parameters/workspace-parameters.component';
import { AddParameterFromCollectionComponent } from './components/workspace-settings/categorization/workspace-parameters/dialogs/add-parameter-from-collection/add-parameter-from-collection.component';
import { TargetParametersComponent } from './components/atTasksDialog/target-parameters/target-parameters.component';
import { AddParameterToTargetComponent } from './components/atTasksDialog/target-parameters/dialogs/add-parameter-to-target/add-parameter-to-target.component';
import { ChangeScenarioDataComponent } from './components/workspace-settings/automation/scenarios/dialogs/change-scenario-data/change-scenario-data.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RemindersComponent } from './components/workspace-pages/cases/reminders/reminders.component';
import { NewParamenterValuesComponent } from './components/workspace-settings/categorization/workspace-parameters/dialogs/new-paramenter-values/new-paramenter-values.component';
import { ChecklistWithParameterComponent } from './components/workspace-settings/automation/checklists/checklist-with-parameter/checklist-with-parameter.component';
import { SelectFilesForCheckComponent } from './components/workspace-pages/cases/reminders/dialogs/select-files-for-check/select-files-for-check.component';
import { SelectTaskTemplateComponent } from './shared/global_components/file-manager/dialog/select-task-template/select-task-template.component';
import { MatAutocompleteOptionsScrollDirective } from './shared/directives/mat-autocomplete-options-scroll.directive';
import { UrlsComponent } from './components/workspace-pages/urls/urls.component';
import { MultiAddExecutorsComponent } from './components/workspace-pages/jobs/dialogs/multi-add-executors/multi-add-executors.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DayViewSchedulerComponent } from './components/workspace-pages/urls/childs/day-view-scheduler/day-view-scheduler.component';
import { RegularPublicationsComponent } from './components/workspace-settings/regular-publications/regular-publications.component';
import { AddAndEditScheduledPostComponent } from './components/workspace-settings/add-and-edit-scheduled-post/add-and-edit-scheduled-post.component';
import { AddRegularComponent } from './components/workspace-settings/regular-publications/dialogs/add-regular/add-regular.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BackJobToPrevStatusComponent } from './shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { SelectEmployeeForEditChatStatus } from './shared/global_components/select-employee-for-edit-chat-status/select-employee-for-edit-chat-status.component';
import { AddChannelToTaskComponent } from './components/atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { MembersDashboardComponent } from './components/workspace-settings/members/members-dashboard/members-dashboard.component';
import { CategorizationDashboardComponent } from './components/workspace-settings/categorization/categorization-dashboard/categorization-dashboard.component';
import { DiscontinuedDashboardComponent } from './components/workspace-settings/discontinued/discontinued-dashboard/discontinued-dashboard.component';
import { MatDividerModule } from '@angular/material/divider';
import { WorkspaceSettingsComponent } from './components/workspace-settings/workspace-settings.component';
import { FinancesDashboardComponent } from './components/workspace-settings/finances/finances-dashboard/finances-dashboard.component';
import { SameFilesComponent } from './shared/global_components/file-interface/dialogs/same-files/same-files.component';
import { TaskTemplateExecutorsComponent } from './components/workspace-settings/automation/scenarios/dialogs/task-template-executors/task-template-executors.component';
import { AddAndEditTaskTemplateExecutorComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-and-edit-task-template-executor/add-and-edit-task-template-executor.component';
import { TaskLinkComponent } from './components/links/task-link/task-link.component';
import { JobLinkComponent } from './components/links/job-link/job-link.component';
import { ChatLinkComponent } from './components/links/chat-link/chat-link.component';
import { MultiCompanyTargetComponent } from './components/links/dialogs/multi-company-target/multi-company-target.component';
import { FilePrivacyComponent } from './shared/global_components/file-manager/dialog/file-privacy/file-privacy.component';
import { FileLinkComponent } from './components/links/file-link/file-link.component';
import { AddTaskProfileComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PublicInterfaceComponent } from './shared/global_components/file-manager/dialog/public-interface/public-interface.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PartnerWorkspacesComponent } from './components/workspace-settings/members/partner-workspaces/partner-workspaces.component';
import { EditPartnerWorkspaceComponent } from './components/workspace-settings/members/partner-workspaces/dialogs/edit-partner-workspace/edit-partner-workspace.component';
import { UserComponent } from './shared/global_components/childrens/user/user.component';
import { MultiEditProfilesStatusComponent } from './components/workspace-pages/urls/dialogs/multi-edit-profiles-status/multi-edit-profiles-status.component';
import { ChatConnectionsComponent } from './components/workspace-pages/chats-page/dialogs/chat-connections/chat-connections.component';
import { EditChatConnectionsComponent } from './components/workspace-pages/chats-page/dialogs/chat-connections/dialogs/edit-chat-connections/edit-chat-connections.component';
import { MatSelectInfiniteScrollDirective } from './shared/directives/mat-select-infinite-scroll';
import { MultiEditJobsTypeComponent } from './components/workspace-pages/jobs/dialogs/multi-edit-jobs-type/multi-edit-jobs-type.component';
import { MultiJobsAssignScenarioComponent } from './components/workspace-pages/jobs/dialogs/multi-jobs-assign-scenario/multi-jobs-assign-scenario.component';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { PermissionsPipe } from './shared/pipes/permissions.pipe';
import { UrlAnalyticsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { UpdateChannelBtnComponent } from './shared/global_components/childrens/update-channel-btn/update-channel-btn.component';
import { ProfilePlaylistComponent } from './components/workspace-settings/publication-outlets/profile-playlist/profile-playlist.component';
import { UrlsDashboardComponent } from './components/workspace-pages/urls-dashboard/urls-dashboard.component';
import { TranslateVerifiedComponent } from './components/admin/dialogs/translate-verified/translate-verified.component';
import { ChannelToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/channel-to-employee/channel-to-employee.component';
import { ShortNumberPipe } from './shared/pipes/short-number.pipe';
import { MobAddTaskProfileComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/mob-add-task-profile/mob-add-task-profile.component';
import { MobEditTaskProfileComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/mob-edit-task-profile/mob-edit-task-profile.component';
import { ChannelGroupToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/channel-group-to-employee/channel-group-to-employee.component';
import { TimeDotsFormatPipe } from './shared/pipes/timeDotsFormat';
import { MobUrlAnalyticsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/mob-url-analytics/mob-url-analytics.component';
import { SelectCompanyComponent } from './components/mob-task/select-company/select-company.component';
import { MobAddTaskComponent } from './components/mob-task/mob-add-task/mob-add-task.component';
import { MobAddTaskUseTemplateComponent } from './components/mob-task/mob-add-task-use-template/mob-add-task-use-template.component';
import { MobAddJobComponent } from './components/mob-job/mob-add-job/mob-add-job.component';
import { MobFileManagerComponent } from './shared/global_components/mob-file-manager/mob-file-manager.component';
import { MobFmNavigationComponent } from './shared/global_components/mob-file-manager/dialogs/mob-fm-navigation/mob-fm-navigation.component';
import { MobFmCreateFolderComponent } from './shared/global_components/mob-file-manager/dialogs/mob-fm-create-folder/mob-fm-create-folder.component';
import { MobFmUploadComponent } from './shared/global_components/mob-file-manager/dialogs/mob-fm-upload/mob-fm-upload.component';
import { MobFmViewComponent } from './shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { MobChangePersonalStatusComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { BoardListPageComponent } from './components/workspace-pages/board-page/board-list-page/board-list-page.component';
import { OutletsDashboardComponent } from './components/workspace-pages/outlets-dashboard/outlets-dashboard.component';
import { MobChatOptionsComponent } from './shared/global_components/chat/dialogs/mob-chat-options/mob-chat-options.component';
import { MobPriorityComponent } from './shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { MobTitleComponent } from './shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { FilePropertiesComponent } from './shared/global_components/file-interface/dialogs/file-properties/file-properties.component';
import { MobQuestionComponent } from './shared/global_components/mob-question/mob-question.component';
import { JobDotsComponent } from './components/mob-job/job-dots/job-dots.component';
import { JobEditComponent } from './components/mob-job/job-edit/job-edit.component';
import { MobTaskEditComponent } from './components/mob-task/mob-task-edit/mob-task-edit.component';
import { ActivityComponent } from './shared/global_components/activity/activity.component';
import { JobEditNameComponent } from './components/mob-job/job-edit-name/job-edit-name.component';
import { SameValuesComponent } from './components/admin/dialogs/same-values/same-values.component';
import { NavigationBarComponent } from './shared/global_components/navigation-bar/navigation-bar.component';
import { JobEditTypeComponent } from './components/mob-job/job-edit-type/job-edit-type.component';
import { JobEditCompletedAtComponent } from './components/mob-job/job-edit-completed-at/job-edit-completed-at.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TestPageComponent } from './components/test-page/test-page.component';
import { PlatformComponent } from './shared/global_components/childrens/platform/platform.component';
import { PushSettingsComponent } from './shared/global_components/push-settings/push-settings.component';
import { ReportsDate } from './shared/pipes/reportsDate';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MobDefaultFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-default-filter/mob-default-filter.component';
import { MobActiveFiltersComponent } from './shared/global_components/navigation-bar/mob-filters/mob-active-filters/mob-active-filters.component';
import { MobSearchFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-search-filter/mob-search-filter.component';
import { CreateRootTypeComponent } from './components/admin/dialogs/create-root-type/create-root-type.component';
import { MobShortcutsComponent } from './shared/global_components/navigation-bar/mob-filters/mob-shortcuts/mob-shortcuts.component';
import { MobStakeholderFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-stakeholder-filter/mob-stakeholder-filter.component';
import { MobTagFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-tag-filter/mob-tag-filter.component';
import { MobTypeFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-type-filter/mob-type-filter.component';
import { MobPlatformFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-platform-filter/mob-platform-filter.component';
import { MobDateFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-date-filter/mob-date-filter.component';
import { MobAutoupdateFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-autoupdate-filter/mob-autoupdate-filter.component';
import { MobStatusFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-status-filter/mob-status-filter.component';
import { MobSortFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-sort-filter/mob-sort-filter.component';
import { AskPasteFilesComponent } from './shared/global_components/ask-paste-files/ask-paste-files.component';
import { MobUpdateIntervalComponent } from './shared/global_components/navigation-bar/mob-filters/mob-update-interval/mob-update-interval.component';
import { MobChannelsFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-channels-filter/mob-channels-filter.component';
import { AddJobToBoardComponent } from './components/workspace-pages/jobs/dialogs/add-job-to-board/add-job-to-board.component';
import { SaveFilterToBookmarkComponent } from './shared/global_components/navigation-bar/dialogs/save-filter-to-bookmark/save-filter-to-bookmark.component';
import { BookmarksComponent } from './components/workspace-settings/categorization/bookmarks/bookmarks.component';
import { EditBookmarkComponent } from './components/workspace-settings/categorization/bookmarks/dialogs/edit-bookmark/edit-bookmark.component';
import { AddEmployeeToBookmarkComponent } from './components/workspace-settings/categorization/bookmarks/dialogs/add-employee-to-bookmark/add-employee-to-bookmark.component';
import { ConnectedAppChannelsComponent } from './components/connected-apps/dialogs/connected-app-channels/connected-app-channels.component';
import { AudioPlayerComponent } from './shared/global_components/childrens/audio-player/audio-player.component';
import { MatSliderModule } from '@angular/material/slider';
import { CreateArchiveComponent } from './shared/global_components/file-manager/dialog/create-archive/create-archive.component';
import { SelectStatusAndEmployeeComponent } from './components/workspace-pages/cases/dialogs/select-status-and-employee/select-status-and-employee.component';
import { SortByPipe } from './shared/pipes/sort.pipe';
import { SwipeDirective } from './shared/directives/swipe.directive';
import { DropFilesLimitComponent } from './shared/global_components/drop-files-limit/drop-files-limit.component';
import { FilesComponent } from './components/workspace-pages/files/files.component';
import { AddEditNoteComponent } from './shared/global_components/add-edit-note/add-edit-note.component';
import { NotesComponent } from './shared/global_components/notes/notes.component';
import { SelectParameterComponent } from './components/atTasksDialog/target-parameters/dialogs/select-parameter/select-parameter.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MultiEditExecutorsComponent } from './components/workspace-pages/chats-page/dialogs/multi-edit-executors/multi-edit-executors.component';
import { CreateDocumentComponent } from './shared/global_components/file-manager/dialog/create-document/create-document.component';
import { FileSpeachToTextComponent } from './shared/global_components/file-manager/dialog/file-speach-to-text/file-speach-to-text.component';
import { CheckListItemsComponent } from './shared/global_components/childrens/check-list/check-list-items.component';
import { FilesSettingsToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/files-settings-to-employee/files-settings-to-employee.component';
import { PrivacyPageComponent } from './components/privacy-page/privacy-page.component';
import { FolderArchivesComponent } from './shared/global_components/file-manager/dialog/folder-archives/folder-archives.component';
import { NotificationsCenterComponent } from './shared/global_components/notifications-center/notifications-center.component';
import { MobNotificationsCenterComponent } from './shared/global_components/mob-notifications-center/mob-notifications-center.component';
import { PublishToOutletComponent } from './shared/global_components/file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { OutletLineComponent } from './shared/global_components/childrens/outlet-line/outlet-line.component';
import { SelectFileComponent } from './shared/global_components/file-manager/dialog/select-file/select-file.component';
import { SelectOutletComponent } from './shared/global_components/mob-file-manager/dialogs/select-outlet/select-outlet.component';
import { MobPublishToOutletComponent } from './shared/global_components/mob-file-manager/dialogs/mob-publish-to-outlet/mob-publish-to-outlet.component';
import { MobTaskEditGroupComponent } from './components/mob-task/mob-task-edit-group/mob-task-edit-group.component';
import { VideoEdtiorComponent } from './shared/global_components/file-manager/dialog/video-edtior/video-edtior.component';
import { MainVideoEditorComponent } from './shared/global_components/file-manager/dialog/main-video-editor/main-video-editor.component';
import { VideoEditorTimeFormatPipe } from './shared/pipes/videoEditorTimeFormat.pipe';
import { DeviceService } from './shared/services/common/device.service';
import { DeviceClassDirective } from './shared/directives/deviceClass.directive';
import { ProjectsComponent } from './shared/global_components/file-manager/dialog/main-video-editor/dialog/projects/projects.component';
import { BetaVideoEditorComponent } from './shared/global_components/file-manager/dialog/beta-video-editor/beta-video-editor.component';
import { TestVeComponent } from './components/test-ve/test-ve.component';
import {NgxDragResizeModule} from 'ngx-drag-resize';
import { ResizableDraggableComponent } from './shared/global_components/resizable-draggable/resizable-draggable.component';
import { ScenarioToGroupComponent } from './components/workspace-settings/automation/scenarios/dialogs/scenario-to-group/scenario-to-group.component';
import { AddGroupToScenarioComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-group-to-scenario/add-group-to-scenario.component';
import { ChannelsToGroupComponent } from './components/workspace-settings/categorization/groups/channels-to-group/channels-to-group.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AlphaVideoEditorComponent } from './shared/global_components/file-manager/dialog/alpha-video-editor/alpha-video-editor.component';
import { CommentsComponent } from './shared/global_components/comments/comments.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CreateCardsComponent } from './shared/global_components/file-manager/dialog/main-video-editor/dialog/create-cards/create-cards.component';
import { SharedOptionsComponent } from './shared/global_components/shared-options/shared-options.component';
import { AddEditOptionComponent } from './shared/global_components/shared-options/dialogs/add-edit-option/add-edit-option.component';
import { TagTaskComponent } from './shared/global_components/tag-task/tag-task.component';
import { MobPushSettingsComponent } from './shared/global_components/chat/dialogs/mob-push-settings/mob-push-settings.component';
import { TipsInterfaceComponent } from './shared/global_components/tips-interface/tips-interface.component';
import { SetEditCardComponent } from './shared/global_components/set-edit-card/set-edit-card.component'; 
import { MatSelectScrollBottomDirective } from './shared/directives/mat-select-bottom-scroll.directive';
import { CompilationsWizardComponent } from './shared/global_components/compilations-wizard/compilations-wizard.component';
import { MultiEditGroupsComponent } from './components/atTasksDialog/multi-edit-groups/multi-edit-groups.component';
import { UserScheduleComponent } from './shared/global_components/user-schedule/user-schedule.component';
import { DrawComponent } from './shared/global_components/draw/draw.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DrawerComponent } from './shared/global_components/drawer/drawer.component';
import { FullTimeFormatPipe } from './shared/pipes/fullTime.pipe';
import { CompanyStructureComponent } from './components/workspace-settings/categorization/company-structure/company-structure.component';
import { PositionToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/position-to-employee/position-to-employee.component';
import { PostToPlanComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/post-to-plan/post-to-plan.component';
import { OutletFilterComponent } from './shared/global_components/childrens/outlet-filter/outlet-filter.component';
import { AddCardAutomationTemplateComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-card-automation-template/add-card-automation-template.component';
import { CardScenarioComponent } from './components/atTasksDialog/card-scenario/card-scenario.component';
import { AddCardScenarioComponent } from './components/atTasksDialog/card-scenario/dialogs/add-card-scenario/add-card-scenario.component';
import { GroupToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/group-to-employee/group-to-employee.component';
import { UserCalendarComponent } from './shared/global_components/user-calendar/user-calendar.component';
import { EditDayComponent } from './shared/global_components/user-calendar/dialogs/edit-day/edit-day.component';
import { ChannelEditDescriptionComponent } from './components/connected-apps/dialogs/channel-edit-description/channel-edit-description.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { AutoSchemeComponent } from './components/workspace-settings/automation/scenarios/dialogs/auto-scheme/auto-scheme.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SetEditSourceComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-automation-template/dialogs/set-edit-source/set-edit-source.component';
import { SetEditDestinationComponent } from './components/workspace-settings/automation/scenarios/dialogs/add-automation-template/dialogs/set-edit-destination/set-edit-destination.component';
import { AutoPositionComponent } from './components/workspace-settings/members/employees/dialogs/auto-position/auto-position.component';
import { ScenEcexecutorsComponent } from './components/workspace-settings/automation/scenarios/dialogs/scen-ecexecutors/scen-ecexecutors.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SetEditFoldersPermissionComponent } from './components/workspace-settings/members/dialogs/set-edit-folders-permission/set-edit-folders-permission.component';
import { FoldersPermissionsComponent } from './components/workspace-settings/members/dialogs/folders-permissions/folders-permissions.component';
import { AutoProjectsComponent } from './shared/global_components/file-manager/dialog/auto-projects/auto-projects.component';
import { ScrnSettingsComponent } from './shared/global_components/file-manager/dialog/scrn-settings/scrn-settings.component';
import { SetAutoForCheckComponent } from './components/workspace-settings/automation/checklists/set-auto-for-check/set-auto-for-check.component';
import { SelectCheckAllComponent } from './shared/global_components/childrens/select-check-all/select-check-all.component';
import { PrimaryTagForFilesComponent } from './shared/global_components/file-manager/dialog/primary-tag-for-files/primary-tag-for-files.component';
import { MobAssignmentsComponent } from './components/mob-job/mob-assignments/mob-assignments.component';
import { AutoTagComponent } from './components/workspace-settings/automation/scenarios/dialogs/auto-tag/auto-tag.component';
import { DropboxesComponent } from './components/workspace-settings/publication-outlets/dropboxes/dropboxes.component';
import { DropboxComponent } from './components/workspace-settings/publication-outlets/dropboxes/dialogs/dropbox/dropbox.component';
import { DropboxKeysComponent } from './components/workspace-settings/publication-outlets/dropboxes/dialogs/dropbox-keys/dropbox-keys.component';
import { DropboxChannelComponent } from './components/workspace-settings/publication-outlets/dropboxes/dialogs/dropbox-channel/dropbox-channel.component';
import { NoteRelationsComponent } from './shared/global_components/notes/dialogs/note-relations/note-relations.component';
import { MultiJobsExecScenariosComponent } from './components/workspace-pages/jobs/dialogs/multi-jobs-exec-scenarios/multi-jobs-exec-scenarios.component';
import { VideoEditorTestComponent } from './shared/global_components/file-manager/dialog/video-editor-test/video-editor-test.component';
import { CardsProjectsListComponent } from './shared/global_components/file-manager/dialog/cards-projects-list/cards-projects-list.component';
import { CardProjectComponent } from './shared/global_components/file-manager/dialog/cards-projects-list/dialog/card-project/card-project.component';
import { PriceToEmployeeComponent } from './components/workspace-settings/members/employees/dialogs/price-to-employee/price-to-employee.component';
import { CtrlScrollDirective } from './shared/directives/сtrlWheel.directive';
import { PostsNamesComponent } from './shared/global_components/file-manager/dialog/main-video-editor/dialog/posts-names/posts-names.component';
import { VideoEditorPageComponent } from './shared/global_components/file-manager/dialog/video-editor-page/video-editor-page.component';
import { VideoEditorV5Component } from './shared/global_components/file-manager/dialog/video-editor-v5/video-editor-v5.component';
import { MenuAimDirective } from './shared/directives/menu.directive';
import { ProjectsPageComponent } from './components/workspace-pages/projects-page/projects-page.component';
import { ProjectsInterfaceComponent } from './shared/global_components/file-manager/dialog/main-video-editor/dialog/projects-interface/projects-interface.component';
import { LiteFileManagerComponent } from './shared/global_components/lite-file-manager/lite-file-manager.component';
import { UsageComponent } from './components/workspace-settings/usage/usage.component';
import { LiteJobComponent } from './components/workspace-pages/cases/dialogs/lite-job/lite-job.component';
import { MoveAllCardJobsComponent } from './components/workspace-pages/cases/dialogs/move-all-card-jobs/move-all-card-jobs.component';
import { LiteChatsComponent } from './shared/global_components/lite-chats/lite-chats.component';
import { LiteExecutorsComponent } from './shared/global_components/lite-executors/lite-executors.component';
import { WorkspaceLimitsComponent } from './components/admin/dialogs/workspace-limits/workspace-limits.component';
import { MultiMigrateComponent } from './shared/global_components/file-manager/dialog/multi-migrate/multi-migrate.component';
import { MigrateTargetFilesComponent } from './shared/global_components/migrate-target-files/migrate-target-files.component';
import { ReplacementsSettingsComponent } from './shared/global_components/file-manager/dialog/auto-projects/dialog/replacements-settings/replacements-settings.component';
import { StatementsTemplatesComponent } from './components/workspace-settings/finances/statements/statements-templates/statements-templates.component';
import { StatementDocumentsComponent } from './components/workspace-settings/finances/statements/dialogs/statement-documents/statement-documents.component';
import { AddEditStTemplateComponent } from './components/workspace-settings/finances/statements/statements-templates/dialogs/add-edit-st-template/add-edit-st-template.component';
import { AddEditStatementDocumentsComponent } from './components/workspace-settings/finances/statements/dialogs/add-edit-statement-documents/add-edit-statement-documents.component';
import { AddVariableComponent } from './components/workspace-settings/finances/statements/statements-templates/dialogs/add-variable/add-variable.component';
import { AddTableComponent } from './components/workspace-settings/finances/statements/statements-templates/dialogs/add-table/add-table.component';
import { AddEditLiveStreamComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/add-edit-live-stream/add-edit-live-stream.component';
import { AddDateVariableComponent } from './components/workspace-settings/finances/statements/statements-templates/dialogs/add-date-variable/add-date-variable.component';
import { EditColumnComponent } from './components/workspace-settings/finances/statements/statements-templates/dialogs/add-table/dialogs/edit-column/edit-column.component';
import { LivesSettingsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/lives-settings/lives-settings.component';
import { GetFilesSettingsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/get-files-settings/get-files-settings.component';
import { MultiDeleteClientsComponent } from './components/workspace-pages/jobs/dialogs/multi-delete-clients/multi-delete-clients.component';
import { MultiDeleteTagsComponent } from './components/workspace-pages/jobs/dialogs/multi-delete-tags/multi-delete-tags.component';
import { LiteBoardComponent } from './shared/global_components/lite-board/lite-board.component';
import { ServersTableComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/servers-table/servers-table.component';
import { ChannelsTableComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/channels-table/channels-table.component';
import { ProjectsServersTableComponent } from './shared/global_components/file-manager/dialog/main-video-editor/projects-servers-table/projects-servers-table.component';
import { AskTextComponent } from './shared/global_components/ask-text/ask-text.component';
import { ManageAuthComponent } from './components/workspace-settings/publication-outlets/manage-auth/manage-auth.component';
import { ChangePriorityComponent } from './shared/global_components/change-priority/change-priority.component';
import { ImportFilesComponent } from './components/workspace-pages/cases/dialogs/import-files/import-files.component';
import { AddEditViewVariableComponent } from './components/workspace-pages/projects-page/diaglos/add-edit-view-variable/add-edit-view-variable.component';
import { AddEditViewColumnComponent } from './components/workspace-pages/projects-page/diaglos/add-edit-view-column/add-edit-view-column.component';
import { MobScheduledTypesFilterComponent } from './shared/global_components/navigation-bar/mob-filters/mob-scheduled-types-filter/mob-scheduled-types-filter.component';
import { ImportDataComponent } from './components/workspace-settings/publication-outlets/import-data/import-data.component';
import { AddImportComponent } from './components/workspace-settings/publication-outlets/import-data/dialogs/add-import/add-import.component';
import { DuplicateSettingsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/servers-table/dialogs/duplicate-settings/duplicate-settings.component';
import { AddEditPostDocumentComponent } from './shared/global_components/add-edit-post-document/add-edit-post-document.component';
import { ChatStatusesComponent } from './shared/global_components/chat/dialogs/chat-statuses/chat-statuses.component';
import { DeleteTagFromSelectedComponent } from './shared/global_components/delete-tag-from-selected/delete-tag-from-selected.component';
import { PostExtraInfoComponent } from './shared/global_components/post-extra-info/post-extra-info.component';
import { TotalUsageComponent } from './components/workspace-settings/usage/total-usage/total-usage.component';
import { HoverUserInfoDirective } from './shared/directives/userHover.directive';
import { UserInfoComponent } from './shared/global_components/user-info/user-info.component';
import { ChannelsCounterTableComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/channels-counter-table/channels-counter-table.component';
import { SetPrivacyStatusComponent } from './components/workspace-pages/urls/dialogs/set-privacy-status/set-privacy-status.component';
import { CardClipboardComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/card-clipboard/card-clipboard.component';
import { AddClipVariableComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/card-clipboard/add-clip-variable/add-clip-variable.component';
import { AddEditAiSchemeComponent } from './shared/global_components/add-edit-ai-scheme/add-edit-ai-scheme.component';
import { AiSchemeVariableComponent } from './shared/global_components/add-edit-ai-scheme/ai-scheme-variable/ai-scheme-variable.component';
import { ShowAnswerComponent } from './shared/global_components/add-edit-ai-scheme/show-answer/show-answer.component';
import { ManagePostsComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/manage-posts/manage-posts.component';
import { HighlightDirective } from './shared/directives/highlight.directive';
import { SafeHtmlPipe } from './shared/pipes/safeHtml.pipe';
import { PostsCleanerComponent } from './shared/global_components/cleaners/posts-cleaner/posts-cleaner.component';
import { ImportFilesV2Component } from './components/workspace-pages/cases/dialogs/import-files-v2/import-files-v2.component';
import { LinkAndCreateOutletComponent } from './components/workspace-settings/publication-outlets/manage-auth/dialogs/link-and-create-outlet/link-and-create-outlet.component';
import { VideoFfmpegEditorComponent } from './shared/global_components/file-manager/dialog/video-ffmpeg-editor/video-ffmpeg-editor.component';
import { UrlsStartComponent } from './components/workspace-pages/urls/urls-start/urls-start.component';
import { UrlsAnalyticComponent } from './components/workspace-pages/urls/urls-analytic/urls-analytic.component';
import { StreamRestartAssistantComponent } from './components/workspace-pages/cases/dialogs/open-task/dialogs/stream-restart-assistant/stream-restart-assistant.component';
import { CompilationAssistantComponent } from './components/workspace-pages/cases/dialogs/compilation-assistant/compilation-assistant.component';
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeRu);
registerLocaleData(localeUk);

// registerLocaleData(enUS)
// registerLocaleData(es)
// registerLocaleData(ru)
// registerLocaleData(uk)

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
  declarations: [
    AppComponent,
    SnackBarItem,
    NotificationsBar,
    MainComponent,
    HeaderComponent,
    CompanyCreateComponent,
    CompanyEditComponent,
    CompanyComponent,
    GroupEditComponent,
    DialogGroupEditComponent,
    DialogGroupCreateComponent,
    GroupCreateComponent,
    ProfileCreateComponent,
    ProfileEditComponent,
    ProfileComponent,
    UsersComponent,
    UserCreateComponent,
    UserEditComponent,
    TaskEditComponent,
    WorkAddComponent,
    WorkMultiAddComponent,
    WorkEmplAddComponent,
    WorkEditComponent,
    TaskProfileAddComponent,
    TaskProfileEditComponent,
    EmployeeAddComponent,
    EmployeesComponent,
    WorkEmplEditComponent,
    ReportsNumber,
    FullTimeFormatPipe,
    SafeHtmlPipe,
    EmplCreateTaskComponent,
    CreateTaskComponent,
    TaskCommentEditComponent,
    EmployeeEditComponent,
    GroupAddComponent,
    TaskGroupEditComponent,
    EditEmplStatusComponent,
    TaskStatusEditComponent,
    TaskIdEditComponent,
    WorkOperationIdEditComponent,
    WorkCompletedAtEditComponent,
    WorkEmployeeEditComponent,
    WorkStatusEditComponent,
    WorkRateEditComponent,
    WorkCommentEditComponent,
    WorkEmployeeCommentEditComponent,
    LongPressDirective,
    HoverUserInfoDirective,
    SelectDirective,
    StatByMonthComponent,
    MatSelectScrollBottomDirective,
    DeviceClassDirective,
    CloseFromBackdropComponent,
    TasksHaveSameUrlComponent,
    WorkToApproveComponent,
    ConnectionsModalComponent,
    SplitPipe,
    PermissionsPipe,
    ShortNumberPipe,
    DateAskComponent,
    OperationChatComponent,
    CreateOperationChatComponent,
    OperationChatsComponent,
    LoaderMoreComponent,
    EditOperationChatComponent,
    ChatsComponent,
    YearPipe,
    ReportsDate,
    TimeFormatPipe,
    TimeDotsFormatPipe,
    SortByPipe,
    SafePipe,
    VideoEditorTimeFormatPipe,
    StatByMonthCreatorComponent,
    SameUrlTasksComponent,
    MobChatStatusEditComponent,
    ChatComponent,
    ClientsListComponent,
    EditClientComponent,
    AddClientComponent,
    TaskClientsComponent,
    TaskAddClientComponent,
    PaymentsComponent,
    AddPaymentComponent,
    EditPaymentComponent,
    CreateChecklistComponent,
    EditChecklistComponent,
    ChecklistComponent,
    CheckListComponent,
    QueastsListComponent,
    AddQuestComponent,
    EditQuestComponent,
    AddFieldComponent,
    EditFieldComponent,
    AddQuestToEmplComponent,
    QuestComponent,
    AdditionalEmployeesComponent,
    AddAdditionalEmployeesComponent,
    EditAdditionalEmployeesComponent,
    ChangeStatusComponent,
    ReminderAlertComponent,
    DeleteAlertComponent,
    BackJobToPrevStatusComponent,
    SelectEmployeeForEditChatStatus,
    FileInterfaceComponent,
    FileSizePipe,
    SizePerSec,
    fromNowToTime,
    FileManagerComponent,
    RenameComponent,
    MoveComponent,
    CreateFolderComponent,
    VideoViewerComponent,
    FileSettingsComponent,
    IntPipe,
    DndDirective,
    SwipeDirective,
    FilesUploadAskComponent,
    EnterSubmitDirective,
    HighlightDirective,
    MatAutocompleteOptionsScrollDirective,
    MatSelectInfiniteScrollDirective,
    CtrlScrollDirective,
    MenuAimDirective,
    CasesComponent,
    OpenTaskComponent,
    InterfaceComponent,
    WorkEmplStatusEditComponent,
    DashboardComponent,
    ChangeSizeLimitComponent,
    InviteDialogComponent,
    RulesComponent,
    EditPublishedAtComponent,
    EditStatusComponent,
    EditChannelComponent,
    EditContentNameComponent,
    LoaderRequestsComponent,
    TaskMultiAddClientsComponent,
    SetUpComponent,
    SetComponent,
    CreateTaskStatusComponent,
    EditTaskStatusComponent,
    EditWorkStatusComponent,
    CreateWorkStatusComponent,
    JobTypesComponent,
    TaskTypesComponent,
    PaymentInfoComponent,
    TaskManagerAddComponent,
    GroupManagerAddComponent,
    GroupManagersComponent,
    TaskManagersComponent,
    PartnersListComponent,
    AddPartnerComponent,
    PartnerDialogComponent,
    ErrorPageComponent,
    SetEmplComponent,
    ExecutorsComponent,
    EditPriceComponent,
    EditTypeComponent,
    EditExecutorComponent,
    EditCompletedAtComponent,
    ChatsPageComponent,
    EditChatStatusComponent,
    EditStartAtComponent,
    EditChatAssStatusComponent,
    BoardPageComponent,
    ChooseStatusComponent,
    PersonalStatusesComponent,
    MobPersonalStatusesComponent,
    MoveJobsComponent,
    StatusReasonComponent,
    MultiEditStartAtComponent,
    AddChatToBoardComponent,
    ToApproveStepperComponent,
    StatementsComponent,
    StatementComponent,
    ConnectedAppsComponent,
    EditChannelAccountComponent,
    ConnectWithYoutubeComponent,
    SelectContentComponent,
    OpenJobComponent,
    MoveToOtherJobComponent,
    JobsComponent,
    WorkspaceDashboardComponent,
    TaskFilterComponent,
    ChatFilterComponent,
    BoardFilterComponent,
    ApproveJobComponent,
    SelectChecklistComponent,
    FinancesComponent,
    ScenariosComponent,
    AddAutomationTemplateComponent,
    EditApproveComponent,
    JobAutomationComponent,
    AddJobAutomationComponent,
    PrivacyComponent,
    TaskChatsComponent,
    MinimizeInterfaceComponent,
    MiniChatComponent,
    CreateNewTaskComponent,
    PartnerCreateTaskComponent,
    AutoComponent,
    AddAndEditTaskTemplateComponent,
    ApproveRejectFilesComponent,
    WorkspaceParametersComponent,
    AddParameterFromCollectionComponent,
    TargetParametersComponent,
    AddParameterToTargetComponent,
    ChangeScenarioDataComponent,
    RemindersComponent,
    NewParamenterValuesComponent,
    ChecklistWithParameterComponent,
    SelectFilesForCheckComponent,
    SelectTaskTemplateComponent,
    UrlsComponent,
    MultiAddExecutorsComponent,
    DayViewSchedulerComponent,
    RegularPublicationsComponent,
    AddAndEditScheduledPostComponent,
    AddRegularComponent,
    AddChannelToTaskComponent,
    MembersDashboardComponent,
    CategorizationDashboardComponent,
    DiscontinuedDashboardComponent,
    WorkspaceSettingsComponent,
    FinancesDashboardComponent,
    SameFilesComponent,
    TaskTemplateExecutorsComponent,
    AddAndEditTaskTemplateExecutorComponent,
    TaskLinkComponent,
    JobLinkComponent,
    ChatLinkComponent,
    MultiCompanyTargetComponent,
    FilePrivacyComponent,
    FileLinkComponent,
    AddTaskProfileComponent,
    PublicInterfaceComponent,
    PartnerWorkspacesComponent,
    EditPartnerWorkspaceComponent,
    UserComponent,
    MultiEditProfilesStatusComponent,
    ChatConnectionsComponent,
    EditChatConnectionsComponent,
    MultiEditJobsTypeComponent,
    MultiJobsAssignScenarioComponent,
    UrlAnalyticsComponent,
    UpdateChannelBtnComponent,
    ProfilePlaylistComponent,
    UrlsDashboardComponent,
    TranslateVerifiedComponent,
    ChannelToEmployeeComponent,
    MobAddTaskProfileComponent,
    MobEditTaskProfileComponent,
    ChannelGroupToEmployeeComponent,
    MobUrlAnalyticsComponent,
    SelectCompanyComponent,
    MobAddTaskComponent,
    MobAddTaskUseTemplateComponent,
    MobAddJobComponent,
    MobFileManagerComponent,
    MobFmNavigationComponent,
    MobFmCreateFolderComponent,
    MobFmUploadComponent,
    MobFmViewComponent,
    MobChangePersonalStatusComponent,
    BoardListPageComponent,
    OutletsDashboardComponent,
    MobChatOptionsComponent,
    MobPriorityComponent,
    MobTitleComponent,
    FilePropertiesComponent,
    MobQuestionComponent,
    JobDotsComponent,
    JobEditComponent,
    MobTaskEditComponent,
    ActivityComponent,
    JobEditNameComponent,
    SameValuesComponent,
    NavigationBarComponent,
    JobEditTypeComponent,
    JobEditCompletedAtComponent,
    TestPageComponent,
    PlatformComponent,
    PushSettingsComponent,
    MobDefaultFilterComponent,
    MobActiveFiltersComponent,
    MobSearchFilterComponent,
    CreateRootTypeComponent,
    MobShortcutsComponent,
    MobStakeholderFilterComponent,
    MobTagFilterComponent,
    MobTypeFilterComponent,
    MobPlatformFilterComponent,
    MobDateFilterComponent,
    MobAutoupdateFilterComponent,
    MobStatusFilterComponent,
    MobSortFilterComponent,
    AskPasteFilesComponent,
    MobUpdateIntervalComponent,
    MobChannelsFilterComponent,
    AddJobToBoardComponent,
    SaveFilterToBookmarkComponent,
    BookmarksComponent,
    EditBookmarkComponent,
    AddEmployeeToBookmarkComponent,
    ConnectedAppChannelsComponent,
    AudioPlayerComponent,
    CreateArchiveComponent,
    SelectStatusAndEmployeeComponent,
    DropFilesLimitComponent,
    FilesComponent,
    AddEditNoteComponent,
    NotesComponent,
    SelectParameterComponent,
    MultiEditExecutorsComponent,
    CreateDocumentComponent,
    FileSpeachToTextComponent,
    CheckListItemsComponent,
    FilesSettingsToEmployeeComponent,
    PrivacyPageComponent,
    FolderArchivesComponent,
    NotificationsCenterComponent,
    MobNotificationsCenterComponent,
    PublishToOutletComponent,
    OutletLineComponent,
    SelectFileComponent,
    SelectOutletComponent,
    MobPublishToOutletComponent,
    MobTaskEditGroupComponent,
    VideoEdtiorComponent,
    MainVideoEditorComponent,
    ProjectsComponent,
    BetaVideoEditorComponent,
    TestVeComponent,
    ResizableDraggableComponent,
    ScenarioToGroupComponent,
    AddGroupToScenarioComponent,
    ChannelsToGroupComponent,
    AlphaVideoEditorComponent,
    CommentsComponent,
    CreateCardsComponent,
    SharedOptionsComponent,
    AddEditOptionComponent,
    TagTaskComponent,
    MobPushSettingsComponent,
    TipsInterfaceComponent,
    SetEditCardComponent,
    CompilationsWizardComponent,
    MultiEditGroupsComponent,
    UserScheduleComponent,
    DrawComponent,
    DrawerComponent,
    CompanyStructureComponent,
    PositionToEmployeeComponent,
    PostToPlanComponent,
    OutletFilterComponent,
    AddCardAutomationTemplateComponent,
    CardScenarioComponent,
    AddCardScenarioComponent,
    GroupToEmployeeComponent,
    UserCalendarComponent,
    EditDayComponent,
    ChannelEditDescriptionComponent,
    AutoSchemeComponent,
    SetEditSourceComponent,
    SetEditDestinationComponent,
    AutoPositionComponent,
    ScenEcexecutorsComponent,
    FoldersPermissionsComponent,
    SetEditFoldersPermissionComponent,
    AutoProjectsComponent,
    ScrnSettingsComponent,
    SetAutoForCheckComponent,
    SelectCheckAllComponent,
    PrimaryTagForFilesComponent,
    MobAssignmentsComponent,
    AutoTagComponent,
    DropboxesComponent,
    DropboxComponent,
    DropboxKeysComponent,
    DropboxChannelComponent,
    NoteRelationsComponent,
    MultiJobsExecScenariosComponent,
    VideoEditorTestComponent,
    CardsProjectsListComponent,
    CardProjectComponent,
    PriceToEmployeeComponent,
    PostsNamesComponent,
    VideoEditorPageComponent,
    VideoEditorV5Component,
    ProjectsPageComponent,
    ProjectsInterfaceComponent,
    LiteFileManagerComponent,
    UsageComponent,
    LiteJobComponent,
    MoveAllCardJobsComponent,
    LiteChatsComponent,
    LiteExecutorsComponent,
    WorkspaceLimitsComponent,
    MultiMigrateComponent,
    MigrateTargetFilesComponent,
    ReplacementsSettingsComponent,
    StatementsTemplatesComponent,
    StatementDocumentsComponent,
    AddEditStTemplateComponent,
    AddEditStatementDocumentsComponent,
    AddVariableComponent,
    AddTableComponent,
    AddEditLiveStreamComponent,
    AddDateVariableComponent,
    EditColumnComponent,
    LivesSettingsComponent,
    GetFilesSettingsComponent,
    MultiDeleteClientsComponent,
    MultiDeleteTagsComponent,
    LiteBoardComponent,
    ServersTableComponent,
    ChannelsTableComponent,
    ProjectsServersTableComponent,
    AskTextComponent,
    ManageAuthComponent,
    ChangePriorityComponent,
    ImportFilesComponent,
    AddEditViewVariableComponent,
    AddEditViewColumnComponent,
    MobScheduledTypesFilterComponent,
    ImportDataComponent,
    AddImportComponent,
    UserInfoComponent,
    DuplicateSettingsComponent,
    AddEditPostDocumentComponent,
    ChatStatusesComponent,
    DeleteTagFromSelectedComponent,
    PostExtraInfoComponent,
    TotalUsageComponent,
    ChannelsCounterTableComponent,
    SetPrivacyStatusComponent,
    CardClipboardComponent,
    AddClipVariableComponent,
    AddEditAiSchemeComponent,
    AiSchemeVariableComponent,
    ShowAnswerComponent,
    ManagePostsComponent,
    PostsCleanerComponent,
    ImportFilesV2Component,
    LinkAndCreateOutletComponent,
    VideoFfmpegEditorComponent,
    UrlsStartComponent,
    UrlsAnalyticComponent,
    StreamRestartAssistantComponent,
    CompilationAssistantComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SimpleSmoothScrollModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatSnackBarModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTableModule,
    OverlayModule,
    NgxDragResizeModule,
    MatDatepickerModule,
    MatDividerModule,
    MatAutocompleteModule,
    ClipboardModule,
    MatTooltipModule,
    LinkyModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatRippleModule,
    DragDropModule,
    NgxTippyModule,
    NgxLongPress2Module,
    MatBottomSheetModule,
    ScrollingModule,
    MatSelectFilterModule,
    MatSlideToggleModule,
    MatExpansionModule,
    NgxOrgChartModule,
    MatTabsModule,
    MatRadioModule,
    SwiperModule,
    MatBadgeModule,
    A11yModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MaterialFileInputModule,
    AngularEditorModule,
    MatProgressBarModule,
    VimeModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    NgxViewerModule,
    MatSidenavModule,
    DragToSelectModule.forRoot(),
    NgAudioRecorderModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    NgxChartsModule,
    InfiniteScrollModule,
    ContenteditableValueAccessorModule,
    ServiceWorkerModule.register('/sw.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 10 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:10000'
    }),
    PdfViewerModule,
    MatSliderModule,
    VirtualScrollerModule,
    LazyLoadImageModule,
    ColorPickerModule,
    OrganizationChartModule,
    SkeletonModule
  ],
  entryComponents: [
    SnackBarItem,
    TaskGroupEditComponent,
    CloseFromBackdropComponent,
    TasksHaveSameUrlComponent,
    WorkToApproveComponent,
    DeleteAlertComponent,
    BackJobToPrevStatusComponent,
    EditApproveComponent,
    MobAddTaskProfileComponent,
    MobEditTaskProfileComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: LOCALE_ID, useValue: 'en'},
    { provide: LOCALE_ID, useValue: 'ru'},
    { provide: LOCALE_ID, useValue: 'es'},
    { provide: LOCALE_ID, useValue: 'uk'},
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AudioRecordingService,
    VideoRecordingService,
    DeviceService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient, meta: Meta): TranslateHttpLoader {
  if (environment.production) {
    return new TranslateHttpLoader(http, `${document.head.querySelector("[name=assets_path]")['content']}assets/i18n/`, '.json');
  } else {
    return new TranslateHttpLoader(http);
  }
}